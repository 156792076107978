.step-progress {
    @include make-container();
    border: black 1px solid;

    .step {
         @include make-grid-columns();
         content: &nbsp;
         height: 1rem;
         border: blue 1px solid;
    }

    .step-complete.step-1 {
        background-color: blue;
    }
}