$margin-sides: 0;
$text-item-gutter: 5px;

.download-reports-container {
    margin-top: 4rem;

    .reports-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 1.2rem;
        margin-top: 1.2rem;
    }
}

.download-reports-container--preparing {
    margin-top: 24px;
}

.download-reports-divider {
    /*Separates the reports from the assessments*/
    height: 0;
    border-top: solid 2px #e5e5e5;
    margin-top: 4rem;
}

.download-reports-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.4px;
    margin-bottom: 12px;
}

.download-reports-report {
    display: inline-block;
    width: 50%;
    margin-top: 13px;
}

.download-reports-button {
    display: flex;
    align-items: center;
    justify-content: left;

    background-color: transparent;
    border-style: none;
    cursor: pointer;
    margin-top: 1rem;
    width: 130px;
    min-width: 140px;
}

.download-reports-text-container {
    display: inline-block;

    text-align: left;
}

.download-reports-icon {
    @extend .dashboard-icon;
    @extend .dashboard-icon-download;

    margin-right: 8px;
}

.download-reports-name {
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-right: $text-item-gutter;
}

.download-reports-date {
    @extend .download-reports-name;

    margin-right: $text-item-gutter;
}

.download-reports-wrapper {
    background-color: $white;
    border-radius: 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.17);
    flex-grow: 1;
    padding: 35px;
}

.download-reports-label {
    color: #007bc7;
    font-size: 10px;
    font-weight: 700;
}

.video-icon {
    @extend .dashboard-icon;
    @extend .dashboard-icon-video;
    margin-right: 8px;
}

.download-reports-icon-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    justify-content: space-between;

    .spinner-inline {
        width: 130px;
    }
}