.sp-waiting-publication {
    &-container{
        margin-top: 40px;
        margin-bottom: 0;
    }
    &-icon {
        @extend .dashboard-icon;
        @extend .dashboard-icon-info;
        vertical-align: middle;
        margin-right: 8px;
    }
    &-message {
        vertical-align: middle;
        font-family: ProximaNova, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: 0.2px;
        color: #000000;
    }
}