 header {
     .navigation-brand-image {
         float: right;
     }
 }

 .hamburger-menu {
     left: 5px;
     right: inherit;
 }

 .hamburger-menu-icon-container {
     text-align: left;
 }

 .hamburger-menu-content::after {
     right: inherit;
     left: 0;
 }