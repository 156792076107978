$modal-button-colour: #457ba2;

.modal-header {
    display: flex;
    flex-direction: row;
    background-color: #457ba2;
    color: #ffffff;
    padding: 12px 24px 12px 24px;
}

.modal-body {
    background-color: #ffffff;
    padding: 12px 24px 12px 24px;
}

.modal-footer {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    padding: 12px 24px 12px 24px;
    justify-content: center;
}

.modal-button {
    background-color: $modal-button-colour;
    border: solid 1px #457ba2;
    color: white;
    padding: 7px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.modal-button:focus {
    @include button-focus(white, $modal-button-colour);
}

.modal-cancel {
    background-color: transparent;
    color: #457ba2;

    &:focus {
        @include button-focus(#457ba2, $modal-button-colour);
    }
}

.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 10000000;
}
