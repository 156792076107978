$font-size-xl: 1.5rem;
$font-size-lg: 1.125rem;
$font-size-md: 1rem;
$font-size-sm: .88rem;
$font-size-xs: .75rem;
$font-size-banner: 2.5rem;
$header-height: 4rem;
$font-proxima: "ProximaNova";
$grey:#959595;
$default-grey:#f2f2f2;
$black: #2e2e2e;
$teal-blue: #007da4;

/*footer*/
$brandbar-green: #106550;
$brandbar-height: 1.25rem;
$footerbar-height: 4rem;
$footer-position: fixed;
$footer-fontsize: .755rem;
$footerlink-fontsize: .755rem;
$banner-image-url-desktop:"assets/images/dash_header_desktop.jpg";
$banner-image-url-mobile:"assets/images/dash-header-mobile.jpg";

@import "./colors.scss";
@import "./icons.scss";
@import "./mixins.scss";
