.container__large {
  .main-layout-header, .main-layout-footer {
    display: none;
  }

  .container, .centered-layout, .dashboard-progress {
    width: 100%;
  }

  .main-layout-content {
    margin-top: 0;
  }

  .assessment-dashboard-container {
    margin-bottom: 0;
  }
}