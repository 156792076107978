.console {
    font-size: .755rem;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9999;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;   
    color: white;

    &.console-local {
        background-color: #ff0000;
    }
    &.console-KFAS-Dashboard-Dev {
        background-color: #8A2BE2;
    }
    &.console-KFAS-Dashboard-Qa {
        background-color: #1E90FF;
    }
}