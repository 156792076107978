.complete-assessments {
    margin-top: 2rem;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.17);
    width: 100%;
    @extend .container-fluid;

    &-table {
        .assessment-container-item-progress {
            flex-grow: 4;
        }
        p {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
.complete-assessments-title {
    margin-top: 4rem;
}

@include media-breakpoint-down(md) {
    .complete-assessments {
        background-color: transparent;
        box-shadow: none;
        border-top: solid 1px $concrete;
        padding-top: 2rem;
        padding-left: 0;
        padding-right: 0;
        margin-top: 2rem;
        .assessment-container {
            &-date {
                flex: 0 0 100%;
                max-width: 100%;
                border: none;
                justify-content: center;
                .dashboard-icon {
                    margin-right: .5em;
                }
            }
            &-action-complete {
                width: 100%;
                a {
                    border: 1px solid $concrete;
                    border-radius: 1px;
                    padding: .5em;
                    &:not(:last-child) {
                        margin-bottom: .75rem;
                    }
                }
            }
            &-item-progress {
                min-width: 100%;
            }
        }
    }
}

@include media-breakpoint-only(md) {
    .complete-assessments {
        @include flex-row(wrap, space-between, stretch);
        &>div {
            width: 49%;
            &:not(:last-child) {
                margin: 0;
            }
        }
    }
}

.complete-assessments-message {
    &-container {
        @include flex-col(center, center);
        margin-top: 8rem;
        text-align: center;
        width: 100%;
        gap: 1.5rem;

        h2 {
            font-size: 1.125rem;
            font-weight: bold;        
        }

        p {
            margin-bottom: 0;
            max-width: 32rem;
            @include media-breakpoint-down(md) {
                width: 80%;
            }
        }

        .close-window-action {
            &-container {
                width: 12rem;
            }
            &-button {
                @include dashboard-action-button($white, $color-deep-cerulean-dark, $color-deep-cerulean-dark);
                width: 100%;
                @include media-breakpoint-down(md) {
                    width: 70%;
                }
            }
        }
    }
}
