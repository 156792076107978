.dashboard-progress {
    @mixin progress-tile($colors) {
        .tile-#{length($colors)}.complete {
            @for $i from 1 through length($colors) {
                &:nth-child(#{length($colors)}n+#{$i}) {
                    background: nth($colors, $i)
                }
            }
        }
    }
    @extend .container;
    padding: 1.75rem 0;
    &-bar {
        @include flex-row(nowrap, flex-start, stretch);
        height: 13px;
        border-radius: 2px;
        background-color: $bon-jour;
        margin-left: 5%;
        margin-right:5%
    }
    &-title {
        font-size: $font-size-lg;
        text-align: center;
        color: $mine-shaft;
        &-appeal {
            font-weight: bold;
        }
    &-no-completed {
        font-weight: bold;
         }
        padding-top: 2rem;
    }
    .tile {
        width: 100%;
        background: transparent;
    }
    @include progress-tile($colors-1);
    @include progress-tile($colors-2);
    @include progress-tile($colors-3);
    @include progress-tile($colors-4);
    @include progress-tile($colors-5);
    @include progress-tile($colors-6);
    @include progress-tile($colors-7);
    @include progress-tile($colors-8);
    @include progress-tile($colors-9);
    @include progress-tile($colors-10);
}

@include media-breakpoint-down(md) {
    .dashboard-progress {
        padding: 1rem;
        &-bar {
            background-color: transparent;
            height: 12px;
            border-radius: 1px;
        }
        .tile {
            background: $bon-jour;
            &:not(:last-child) {
                margin-right: 1px;
            }
        }
        &-title {
            font-size: 14px;
        }
    }
}