$white: #fff;
$color-ocean-blue: #00789f;
$color-teal-blue: #009bac;

//the next color names genereted by http://chir.ag/projects/name-that-color
$color-chathams-blue: #116578;
$color-bahama-blue: #026c8e;
$color-deep-cerulean: #01809d;
$color-deep-cerulean-dark: #007bc7;
$color-blue-chill: #0c9cb2;
$color-pelorous: #47b8c0;
$color-puerto-rico: #47c0ae;
$color-de-york: #85d28b;
$color-mantis: #77bc49;
$color-fern-frond: #3a6020;

$bon-jour: #e5e1e1;
$bon-jour-light: #e6e2e2;
$concrete: #f2f2f2;
$alabaster: #fafafa;
$mine-shaft: #3a3a3a;
$tundora: #444343;
$emperor: #4f4f4f;

$color-hit-gray: #a8b1b6;
$color-alto: #d8d8d8;

$color-warning: #c00a0a;

$dashboard-grid-heading: scale-color(#a8b1b6, $lightness: -33%);

/*Tiles colors*/
$colors-10: $color-chathams-blue, $color-bahama-blue, $color-deep-cerulean, $color-deep-cerulean-dark, $color-blue-chill, $color-pelorous, $color-puerto-rico, $color-de-york, $color-mantis, $color-fern-frond;
$colors-9: $color-chathams-blue, $color-bahama-blue, $color-deep-cerulean, $color-deep-cerulean-dark, $color-blue-chill, $color-pelorous, $color-puerto-rico, $color-de-york, $color-mantis;
$colors-8: $color-chathams-blue, $color-bahama-blue, $color-deep-cerulean, $color-deep-cerulean-dark, $color-blue-chill, $color-pelorous, $color-puerto-rico, $color-de-york;
$colors-7: $color-chathams-blue, $color-bahama-blue, $color-deep-cerulean, $color-deep-cerulean-dark, $color-blue-chill, $color-pelorous, $color-puerto-rico;
$colors-6: $color-chathams-blue, $color-bahama-blue, $color-deep-cerulean, $color-blue-chill, $color-puerto-rico, $color-de-york;
$colors-5: $color-deep-cerulean, $color-blue-chill, $color-pelorous, $color-puerto-rico, $color-de-york;
$colors-4: $color-deep-cerulean, $color-blue-chill, $color-puerto-rico, $color-de-york;
$colors-3: $color-blue-chill, $color-puerto-rico, $color-de-york;
$colors-2: $color-puerto-rico, $color-de-york;
$colors-1: $color-de-york;