@mixin banner-size($height, $upicSize, $desktop: false) {
    $assessmentBannerUpicPadding: $upicSize * .25;
    $assessmentBannerTitlePadding: $upicSize * .35;
    $assessmentBannerUpicOffset: $height - $upicSize + $assessmentBannerUpicPadding;
    &-background {
        height: $height;
        background-repeat: round;
        
        @if $desktop {
            background-image: url($banner-image-url-desktop);    
        } @else {
            background-image: url($banner-image-url-mobile); 
        }
    }
    &-upic {
        height: $upicSize;
        width: $upicSize;
        margin-top: $assessmentBannerUpicOffset;
    }
    &-title {
        padding-top: $assessmentBannerTitlePadding;
    }
}

.assessment-banner {
    @include banner-size(175px, 112px, $desktop: true);
    text-align: center;
    background-color: $white;
    border-bottom: 1px solid $bon-jour-light;

    &-upic {
        border-radius: 50%;
        overflow: hidden;
        background-color: $tundora;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border: solid 5px $white;
        display: inline-block;
        img {
            display: inline;
            margin: 0 auto;
            height: 100%;
            width: auto;
        }
    }
    &-title {
        font-size: $font-size-banner;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        color: $mine-shaft;
        margin-bottom: 8px;
    }
}

@include media-breakpoint-down(md) {
    .assessment-banner {
        @include banner-size(60px, 56px, $desktop: false);
        &-upic {
            border: solid 3px $white;
        }
        &-title {
            font-size: 34px;
        }
    }
}

.assessments-complete-message-banner {
    @include banner-size(60px, 112px, $desktop: true);
}