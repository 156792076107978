@mixin button-focus($color, $backgroundColor) {
    outline: 2px solid $backgroundColor !important;
    outline-offset: 2px;
    box-shadow: none;
    color: $color;
    background-color: #007bc7 !important;
}

@mixin dashboard-action-button($color, $backgroundColor, $borderColor) {
    width: 80%;
    @extend .btn;
    @extend .btn-primary;
    font-size: $font-size-sm;
    font-weight: bold;
    color: $color;
    background-color: $backgroundColor;
    border: 1px solid $borderColor;
    border-radius:0px;
    cursor:pointer;
    &:hover,
    &:active {
        color: $color;
        background-color: #005184;
        border: solid 1px rgba(255, 255, 255, 0.3);
        outline: transparent;
    }
    &:active {
        box-shadow: none;
    }

    &:focus {
        @include button-focus($color, $backgroundColor);
    }
}

@mixin unstyled-link($color) {
    &,
    &:active,
    &:hover,
    &:visited,
    &:focus {
        color: $color;
        text-decoration: none;
    }
}

@mixin flex-row($flex-wrap: nowrap, $justify-content: center, $align-items: center) {
    display: flex;
    flex-direction: row;
    flex-wrap: $flex-wrap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin flex-col($flex-wrap: nowrap, $justify-content: center, $align-items: center) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: $justify-content;
    align-items: $align-items;
}