@font-face {
    font-family: 'ProximaNova';
    src: url('./assets/fonts/proximanova/3122C9_0_0.eot');
    src: url('./assets/fonts/proximanova/3122C9_0_0.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/proximanova/3122C9_0_0.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_0_0.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_0_0.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('./assets/fonts/proximanova/3122C9_1_0.eot');
    src: url('./assets/fonts/proximanova/3122C9_1_0.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/proximanova/3122C9_1_0.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_1_0.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_1_0.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('./assets/fonts/proximanova/3122C9_2_0.eot');
    src: url('./assets/fonts/proximanova/3122C9_2_0.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/proximanova/3122C9_2_0.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_2_0.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_2_0.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('./assets/fonts/proximanova/3122C9_3_0.eot');
    src: url('./assets/fonts/proximanova/3122C9_3_0.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/proximanova/3122C9_3_0.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_3_0.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_3_0.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('./assets/fonts/proximanova/3122C9_4_0.eot');
    src: url('./assets/fonts/proximanova/3122C9_4_0.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/proximanova/3122C9_4_0.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_4_0.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_4_0.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('./assets/fonts/proximanova/3122C9_5_0.eot');
    src: url('./assets/fonts/proximanova/3122C9_5_0.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/proximanova/3122C9_5_0.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_5_0.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/proximanova/3122C9_5_0.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: italic;
}