$margin-sides: 0;
$text-item-gutter: 5px;

.eLearning-contents-container {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.17);
    padding: 35px;
    margin-top: 1.2rem;
    margin-left: $margin-sides;
    margin-right: $margin-sides;
    background-color: $white;
}

.eLearning-contents-container--preparing {
    margin-top: 24px;
}

.eLearning-contents-divider {
    /*Separates the eLearning from the assessments*/
    height: 0;
    border-top: solid 2px #e5e5e5;
    margin-top: 4rem;
}

.eLearning-contents-heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.4px;
}

.eLearning-contents-material {
    display: inline-block;
    width: 50%;
    margin-top: 1rem;
}

.eLearning-contents-button {
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
}

.eLearning-contents-text-container {
    display: inline-block;
    text-align: left;
}

.eLearning-contents-name {
    display: inline-block;
    color: #007bc7;
    font-weight: bold;
    size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-right: $text-item-gutter;
}

.eLearning-contents-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.4px;
    margin-bottom: 12px;
    margin-top: 4rem;
}