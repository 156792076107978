.footer {
    position: $footer-position;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    .brandbar {
        height: $brandbar-height;
        background-color: $brandbar-green;
    }
    .footer-bar {
        background-color: $black;
        min-height: $footerbar-height;
        @include flex-row(nowrap, space-between);
        .links-right {
            font-family: $font-proxima;
            font-size: $footerlink-fontsize;
            color: $grey;
            padding-right: 1rem;
            ul {
                margin: 0;
                padding: 0;
                a {
                        @include unstyled-link(inherit);
                    }
            }
            p {
                display: none;
            }
        }
        .copyright {
            margin-left: 1rem;
            color: $white;
            font-size: $footer-fontsize;
            p {
                display: inline;
                margin: 0;
                padding: 0 0 0 .5rem;
                position: relative;
                top: .25rem;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .copyright p {
            display: none !important;
        }
        .links-right {
            text-align: right;
            p {
                color: $white;
                display: inline !important;
            }
        }
    }
}

@media screen and (max-height: 400px){
    .footer {
        position: static;
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .footer-bar {
        height: 64px;
    }
}
