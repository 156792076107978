/// <reference path="..\..\..\styles\mixins.scss" />
/// <reference path="_modal.scss" />

.reuseDialog-modal {
    position: absolute;
    max-width: 90%;
    max-height: 90%;
    top: 50%;
    left: 50%;
    width: 740px;
    height: 510px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 0px;
    border: 0px;
    display: flex;
    flex-direction: column;
}

.reuseDialog-modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 10000000;
}

.reuseDialog-body {
    margin: 0;
    padding: 40px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.reuseDialog-title {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.4px;
    margin: 0;
    margin-bottom: 25px;
    text-transform: capitalize;
}

.reuseDialog-subtitle {
    padding-top: 25px;
    padding-bottom: 20px;
    border-top: solid 1px #333333;
}

.reuseDialog-subtitle,
.reuseDialog-user-details th,
.reuseDialog-user-details td {
    font-size: .755rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.reuseDialog-subtitle,
.reuseDialog-user-details th {
    font-weight: 700;
}

.reuseDialog-explanation {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 20px;
}

.reuseDialog-user-details {
    margin-top: 40px;
    width: 100%;
}

.reuseDialog-user-details th {
    padding-bottom: 10px;
}

.reuseDialog-not-you {
    font-weight: 400;
    font-size: .755rem;
    letter-spacing: 0.4px;
    margin-top: auto;
}

.reuseDialog-not-you-img,
.reuseDialog-not-you-text {
    vertical-align: middle;
}

.reuseDialog-not-you-img {
    margin-right: 7px;
    border-style: none;
}

.reuseDialog-footer {
    @extend .modal-footer;

    justify-content: flex-end;
    padding: 0 40px 40px 40px;
    border-style: none;
    margin-top: auto;
}

.reuseDialog-button {
    @extend .modal-button;

    border-radius: 0;
    //width: 130px;
}

.reuseDialog-button + .reuseDialog-button{
    margin-left: 10px;
}

.reuseDialog-button--retake {
    @extend .modal-cancel;
}