.list-inline-item:not(:last-child) {
    margin-left: 5px;
}

.links-right {
    padding-left: 1rem;
}

.footer {
    .footer-bar {
        .copyright {
            margin-right: 1rem;
            p {
                padding: 0 .5rem 0 0;
            }
        }
    }
}