.dashboard-about {
    $about-margin-bottom: 6rem;
    @extend .row;
    margin: 3rem 0 $about-margin-bottom 0;
    padding: 2rem 1rem 2rem 2rem;
    background-color: $white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.17);
    position: relative;
    &:after{
        content: '';
        position:absolute;
        bottom: calc($about-margin-bottom / -2);
        display: block;
        width: 100%;
        left: 0;
        height: 2px;
        border-bottom: solid 1px $color-alto;
    }
    &-item {
        @extend .row;
        @extend .col;
        &:nth-child(1) {
            @extend .col-10;
            padding-left: 2rem;
        }
        &-text {
            width: 75%;
        }
    }
    .dashboard-icon {
        @include icon-size($icon-hex-lg);
    }
    &-title {
        font-size: $font-size-xl;
        font-weight: bold;
        padding-left: 1rem;
        line-height: 1.5;
    }
    &-description {
        line-height: 1.28em;
        padding-left: 1rem;
        padding-right: 5rem;
        margin-bottom: 0;
    }
    &-action {
        @include flex-row(null, flex-start);
        &-button {
            @include dashboard-action-button($white, $color-deep-cerulean-dark, $color-deep-cerulean-dark);
        }
    }
}

@include media-breakpoint-down(md) {
    .dashboard-about {
        //margin: 3rem 0;
        padding: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-icon {
            display: block;
        }
        &-item {
            &,
            &:nth-child(1) {
                flex: 0 0 100%;
                max-width: 100%;
                margin: 0;
                padding-top: .5rem;
                padding-bottom: .5rem;
                @include flex-row(wrap);
            }
        }
        &-item {
            &,
            &-text,
            &:nth-child(1) {
                padding: 0;
            }
        }
        .dashboard-icon {
            float: none;
            margin-right: 0;
            margin-bottom: .5rem;
            position: relative;
            width: 100%;
            @include icon-size($icon-hex-md);
        }
        &-title {
            font-size: 22px;
        }
        &-title,
        &-description {
            padding: 0 0 1rem 0;
        }
    }
}