   .assessment-container {
       text-align: right;
   }

   .assessment-container-icon {
       float: right;
       margin-left: 1.5rem;
   }

   .assessment-container-time {
       .dashboard-icon {
           margin-left: .7rem;
       }
   }

   .assessment-container-title {
       .dashboard-icon {
           margin-right: .7rem;
       }
   }

   .dashboard-about-description {
       padding: 0;
   }

   .dashboard-icon-complete {
       margin-left: .5rem;
   }

   .dashboard-icon-expired {
        margin-left: .5rem;
    }

   @include media-breakpoint-down(md) {
       .assessment-container {
           text-align: center;
       }
       .assessment-container-item:nth-child(2),
       .incomplete-assessments .incomplete-assessments-grid-title-column:nth-child(1) span:nth-child(2) {
           border-left: 1px solid $color-alto;
           border-right: none;
       }
       .assessment-container-time-added {
           left: -50%;
           right: inherit;
       }
       .incomplete-assessments-locked .incomplete-assessments-title-lock-icon {
           margin-left: .5rem;
       }
   }