@import "../components/dashboard/progress.scss";
@import "../components/dashboard/banner.scss";
@import "../components/dashboard/demographics.scss";
@import "../components/dashboard/assessment.scss";
@import "../components/dashboard/incomplete.scss";
@import "../components/dashboard/complete.scss";
@import "../components/dashboard/downloadReports";
@import "../components/dashboard/reportsPreparing";
@import "../components/dashboard/eLearningContents";
@import "../components/dashboard/successProfileNotPublishedMessage";

body {
    background-color: $alabaster;
}

.assessment-dashboard-container {
    margin-bottom: 15rem;
    font-size: $font-size-md;

    h2,h3,h4,h5 {
        margin-bottom: 0;
    }
}

.aria-label-described {
    width: 0;
    height: 0;
    opacity: 0;
    top: -10000px;
    position: absolute;
}