/// <reference path="..\..\..\styles\mixins.scss" />

.language-dropdown {
    display: flex;
    position: relative;

    .language-go-button {
        @include dashboard-action-button($white, $color-deep-cerulean-dark, $color-deep-cerulean-dark);
        font-size: 1rem;
        margin-left: 10px;
        width: auto;
        height: 100%;
    }
}

#liveRegion {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
}