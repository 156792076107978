@import "./hamburger.scss";

header {
    $border-height: 0.375rem;
    @extend .fixed-top;
    background-color: white;
    height: $header-height;
    .navigation-brand-image {
        float: left;
        img {
            max-height: $header-height - $border-height;
            max-width: 9rem;
        }
        .img-margin {
            margin: 1.125rem 2.25rem;
        }
    }
    nav {
        height: 58px;
        line-height: 33px;
        
        .navbar-brand {
            max-height: 80px;
            max-width: 150px;
        }
        .navbar-languages-container {
            margin-right: 20px;
        }
        .navbar-divider-horizontal {
            background-color: $bon-jour-light;
            width: 2px;
            margin-right: 66px;
            height: 31px;
        }
    }
    &::after {
        content: '';
        bottom: 0;
        position: absolute;
        display: block;
        width: 100%;
        background-color: $concrete;
        height: $border-height;
        left: 0;
    }

    .skip-to-main-content-link {
        position: absolute;
        left: -9999px;
        z-index: 999;
        padding: 0.5em;
        background-color: black;
        color: white;
        opacity: 0;
      }
      .skip-to-main-content-link:focus {
        left: 20%;
        top: 15%;
        transform: translateX(-50%);
        opacity: 1;
        color: #fff;
        border: none;
        text-decoration: none;
        outline: 2px solid #fff;
      }
}

.form-select {
    border-color: $bon-jour-light;
    background-image: url("./assets/icons/chevron.svg");
}

.no-display {
    display: none;
}