
.incomplete-assessments {
    margin-top: 1rem;
    p {
        margin-top: 0;
        margin-bottom: 0;
    }
    &-title {
        font-size: $font-size-xl;
        font-weight: bold;
        color: $mine-shaft;
        padding: 1em;
        padding-top: 0;
        padding-left: 0;
        &-lock-text {
            display: none;
        }
        &-lock-icon {
            display: none;
        }
    }
    &-container {
        background-color: $white;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.17);
        @extend .container-fluid;

        table {
            width: 100%;
        }
    }
    & &-grid-title {
        @extend .row;
        padding: 2em 0 0 0;
        margin: 0;
        text-transform: uppercase;
        font-size: $font-size-xs;
        font-weight: 600;
        letter-spacing: .5px;
        color: $dashboard-grid-heading;
        &-column {
            @extend .assessment-container-column;
            &:nth-child(1) {
                @extend .assessment-container-column, :nth-child(1);
                span {
                    @extend .assessment-container-item;
                }
            }
        }
    }
    &-locked {
        pointer-events: none;
        .assessment-container-action {
            visibility: hidden;
        }
    }
    &-locked &-container {
        filter: grayscale(100%);
    }
    &-locked &-title {
        font-weight: normal;
        @include flex-row(wrap, flex-start);
        &-lock-text {
            font-size: $font-size-sm;
            padding-left: .25rem;
            font-style: italic;
            display: initial;
        }
        &-lock-icon {
            @extend .dashboard-icon;
            margin-left: 2rem;
        }
    }
}

@include media-breakpoint-down(md) {
    .incomplete-assessments {
        margin-top: 3rem;
        &-container {
            background-color: transparent;
            box-shadow: none;
            padding: 0;
        }
        & &-grid-title {
            display: none;
        }
        &-title {
            text-align: center;
            //font-weight: normal;
            padding: 1em;
            padding-top: 0;
            >h2 {
                display: block;
                width: 100%;
                font-size: 20px;
            }
            >span:nth-child(2) {
                text-align: left;
                @include flex-row();
                width: 100%;
            }
        }
        &-locked &-title {
            font-weight: normal;
            &-lock-icon {
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-only(md) {
    .incomplete-assessments {
        &-container {
            @include flex-row(wrap, space-between, stretch);
            & > div {
                width: 49%;
            }
        }
    }
}