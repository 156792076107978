.dynamic-feedback-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $white;
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;

    .modal-body {
        position: relative;
        width: 100%;
        height: 100%;
    }
    iframe {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
    }

    .btn-close {
        position: absolute;
        z-index: 200;
        top: 20px;
        right: 20px;
        color: black;
        @extend .dashboard-icon;
        @extend .dashboard-icon-close;
    }
}

.df-modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 1000000;
}