/// <reference path="..\..\..\styles\mixins.scss" />

.timeout-modal {
    position: absolute;
    max-width: 500px;
    max-height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 0px;
    border: 0px;
    display: flex;
    flex-direction: column;

    .modal-body {
        display: flex;
        flex-direction: row;
        flex: 1;
    }
}
